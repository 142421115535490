import { Route, Routes } from 'react-router-dom';
import PAGE_404 from './pages/404';
import Index from './pages/Index';

function App() {
  return (
    <Routes>
      <Route path='/:client_id' element={<Index />} />
      <Route path="*" element={<PAGE_404 />} />
    </Routes>
  );
}

export default App;
