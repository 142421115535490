import React, { useState, useEffect, useCallback } from 'react'
import { db } from '../firebase.js'
import { getDoc, doc } from 'firebase/firestore'
import { addBookingSubmission } from '../utils/bookingService.js'
import Input from '../components/Input.js'
import file_upload_img from '../assets/images/camera.png'
import { initWidget } from '../utils/chatWidget.js'
import GoogleChatWigdet from '../components/GoogleChatWigdet.js'
import { toast } from 'react-toastify'
import { MESSAGE } from '../constants/Message'
import { ERRORS } from '../constants/Errors'
import TextArea from '../components/TextArea.js'
import { formatMobileNumber } from '../utils/formatters.js'
import { uploadFiles } from '../utils/FirebaseStorage.js'
import SubmitButton from '../components/Button/SubmitButton.js'
import Logo from '../components/Logo/Logo.js'
import { useLocation, useNavigate } from 'react-router-dom'

const formInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  description: '',
  attribution: '',
  completion_date: ''
}

const HandymanForm = ({ profile }) => {
  const searchParams = new URLSearchParams(document.location.search);

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [logoUrl, setLogoUrl] = useState()
  const [form, setForm] = useState(formInitialState)
  const [disabledButton, setDisabledButton] = useState(false)

  const widget = initWidget();
  const location = useLocation()
  const navigate = useNavigate()

  const getClientLogo = useCallback(async () => {
    const docRef = doc(db, 'logos', profile.clientName);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setForm({ ...form,
        firstName: !searchParams.get('fn') ? '' : searchParams.get('fn'),
        lastName: !searchParams.get('ln') ? '' : searchParams.get('ln'),
        email: !searchParams.get('email') ? '' : searchParams.get('email'),
        phone: !searchParams.get('phone') ? '' : searchParams.get('phone'),
        address: !searchParams.get('address') ? '' : searchParams.get('address'),
        city:  !searchParams.get('city') ? '' : searchParams.get('city'),
        state: !searchParams.get('state') ? '' : searchParams.get('state'),
        zip:  !searchParams.get('zip') ? '' : searchParams.get('zip'),
        description: !searchParams.get('desc') ? '' : searchParams.get('desc')
      })
      setLogoUrl(docSnap.data().logoUrl)
    }
  },[profile.clientName])

  useEffect(() => {
    getClientLogo()

  }, [getClientLogo])

  const inputHandler = (e) => {
    const target = e.target;
    const value = target.type === 'tel' ? target.value.replace(/\D/g, '') : target.value;
    setForm({...form, [target.name]: value});
  }

  const phoneNumberHandler = (e) => {
		const number = formatMobileNumber(e.target.value)
		setForm({ ...form, phone: number })
	}

  const fileHandler = (e) => {
    setImages([...images, ...e.target.files]);
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (!form.firstName.trim() || !form.lastName.trim() || !form.email.trim() || !form.description.trim() || !form.phone.trim() || !form.address.trim() || !form.city.trim() || !form.state.trim() || !form.zip.trim() || !form.attribution.trim() || !form.completion_date.trim()) {
      toast.error(ERRORS.ALL_FIELDS)
    } else if (images && images.length > 5) {
      toast.error(ERRORS.IMAGES_LENGTH)
    } else if (form.phone.trim().length < 10) {
      toast.error(ERRORS.PHONE_NUM_LENGTH_ERROR)
    } else if (form.zip.trim().length < 5) {
      toast.error(ERRORS.ZIP_CODE_LENGTH_ERROR)
    } else {

      try {
        setLoading(true)
        setDisabledButton(true)
        const imgsUrl = await uploadFiles(images, profile.clientName)
        await addBookingSubmission(form, imgsUrl, profile)
        toast.success(MESSAGE.SUCCESS_FULL)
        resetForm();
      } catch (err) {
        catchCallback(err)
      } finally {
        setDisabledButton(false)
        setLoading(false)
      }
    }
  }

  // AddBookingService Catch Callback
  const catchCallback = (err) => {
    console.error(err);
    toast.error(err.message)
  }

  const resetForm = () => {
    setImages([]);
    setForm(formInitialState)
    navigate(location.pathname, { replace: true })
  }

  const removeImage = (imgName) => {
    const image = images.filter((img) => img.name !== imgName);
    setImages(image);
  }

  return (
    <div className='pt-20 min-h-screen'>
      <main className='w-full md:w-[500px] px-[5%] md:px-0 lg:[w-800px] mx-auto'>
        <Logo logoUrl={logoUrl} />
        <form className='mt-10' onSubmit={formSubmitHandler}>
          <div>
            <Input
              type='text'
              name='firstName' 
              value={form.firstName}
              label='First Name'
              onChange={inputHandler}
            />

            <Input
              type='text'
              name='lastName'
              value={form.lastName}
              label='Last Name'
              onChange={inputHandler}
            />

            <Input
              type='email'
              name='email'
              value={form.email}
              label='Email'
              onChange={inputHandler}
            />

            <Input
              type='tel'
              name='phone'
              value={form.phone}
              label='Phone Number'
              onChange={phoneNumberHandler}
            />

            <Input
              type='text'
              name='address'
              value={form.address}
              label='Address'
              onChange={inputHandler}
            />
            
            <Input
              type='text'
              name='city'
              value={form.city}
              label='City'
              onChange={inputHandler}
            />
            
            <Input
              type='text'
              name='state'
              value={form.state}
              label='State'
              onChange={inputHandler}
            />
            
            <Input
              label='Zip'
              type='tel'
              name='zip'
              value={form.zip}
              onChange={inputHandler}
              maxLength="5"
            />

            <TextArea
              label={'Tell us about your project'}
              name='description'
              value={form.description}
              onChange={inputHandler}
            />

            <TextArea
              label={'How did you hear about us'}
              name='attribution'
              value={form.attribution}
              onChange={inputHandler}
            />

            <div className='mt-4'>
              <Input
                label='Time frame of project completion'
                type='date'
                name='completion_date'
                value={form.completion_date}
                onChange={inputHandler}
              />
            </div>
          </div>

          <div className='mt-4'>
            <div className='py-3'>
              <p className='text-center font-semibold'>Please provide a photo of your project.</p>
              <p className='text-center font-semibold'>These will give us a better understanding of
               your project. We can then make recommendations to you and provide a quote.</p>
            </div>

            <div className='bg-[#f8f9fa] w-full pb-6'>
              <div className='flex justify-center flex-col items-center'>
                <p className='mb-2 font-semibold'>Click on icon to select images</p>
                <label htmlFor='filesUploader' className='cursor-pointer'>
                  <img 
                    src={file_upload_img} 
                    alt='file upload icon' 
                    width='100' height='100'
                  />
                </label>
                <input 
                  type='file'  
                  id='filesUploader'
                  onChange={fileHandler} 
                  multiple 
                  hidden={true}
                  accept="image/*, video/*"
                />
                { (images &&
                  <ul className='text-black'>
                  {
                    images.length > 0 && images.map((image, i) => {
                      return (
                        <li key={i}>{image.name} <span className='cursor-pointer' onClick={() => removeImage(image.name)}>&#10006;</span></li>
                      )
                    })
                  }
                  </ul>) ?? ''
                }
              </div>
            </div>
          </div>

          <SubmitButton loading={loading} disabled={disabledButton} />
        </form>
      </main>

      { widget && <GoogleChatWigdet client_id={profile.clientName} /> }
    </div>
  )
}

export default HandymanForm
