import React, { useState, useCallback, useLayoutEffect } from "react";
import { db } from "../firebase.js";
import { getDoc, doc } from "firebase/firestore";
import { addBookingSubmission } from "../utils/bookingService.js";
import Input, { CheckBox } from "../components/Input.js";
import { toast } from "react-toastify";
import { MESSAGE } from "../constants/Message";
import { ERRORS } from "../constants/Errors";
import TextArea from "../components/TextArea";
import { validateForm } from "../validation/zarazau.validation.js";
import { formatMobileNumber } from "../utils/formatters.js";
import SubmitButton from "../components/Button/SubmitButton.js";
import Logo from "../components/Logo/Logo.js";
import { useLocation, useNavigate } from "react-router-dom";
import Label from "../components/Label/Label.jsx";
import DropDown from "../components/DropDown/DropDown.js";
import { ZARAZAU_ABDOUT_US, ZARAZAU_SERVICES } from "../utils/consts.js";
import { uploadFiles } from "../utils/FirebaseStorage.js";
import ImageUploader from "../components/ImageUploader/ImageUploader.jsx";

const initialState = {
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	address: "",
	city: "",
	state: "",
	zip: "",
	description: "",
	aboutUs: "",
	service: {
		exteriorPainting: false,
		interiorPainting: false,
		cabinetPainitng: false,
		carpentry: false,
		drywall: false,
		gutter: false,
		roof: false,
		floor: false,
	},
};

const Zarazau = ({ profile }) => {
	const searchParams = new URLSearchParams(document.location.search);

	const [loading, setLoading] = useState(false);
	const [openDropDown, setOpenDropDown] = useState(false);
	const [logoUrl, setLogoUrl] = useState("");
	const [form, setForm] = useState(initialState);
	const [disabledButton, setDisabledButton] = useState(false);
	const [images, setImages] = useState([]);

	const location = useLocation();
	const navigate = useNavigate();

	const getClientLogo = useCallback(async () => {
		const docRef = doc(db, "logos", profile.clientName);
		const docSnap = await getDoc(docRef);

		if (docSnap.exists()) {
			setForm({
				...form,
				firstName: !searchParams.get("fn") ? "" : searchParams.get("fn"),
				lastName: !searchParams.get("ln") ? "" : searchParams.get("ln"),
				email: !searchParams.get("email") ? "" : searchParams.get("email"),
				phone: !searchParams.get("phone") ? "" : searchParams.get("phone"),
				address: !searchParams.get("address") ? "" : searchParams.get("address"),
				city: !searchParams.get("city") ? "" : searchParams.get("city"),
				state: !searchParams.get("state") ? "" : searchParams.get("state"),
				zip: !searchParams.get("zip") ? "" : searchParams.get("zip"),
				description: !searchParams.get("desc") ? "" : searchParams.get("desc"),
			});
			setLogoUrl(docSnap.data().logoUrl);
		}
	}, [profile.clientName]);

	useLayoutEffect(() => {
		getClientLogo();
	}, [getClientLogo]);

	const inputHandler = (e) => {
		const { value, type, name } = e.target;
		const val = type === "tel" ? value.replace(/\D/g, "") : value;
		setForm({ ...form, [name]: val });
	};

	const phoneNumberHandler = (e) => {
		const number = formatMobileNumber(e.target.value);
		setForm({ ...form, phone: number });
	};

	const formSubmitHandler = async (e) => {
		e.preventDefault();

		if (!validateForm(form, profile.clientName)) {
			toast.error(ERRORS.ALL_FIELDS);
		} else if (images && images.length > 5) {
			toast.error(ERRORS.IMAGES_LENGTH);
		} else if (form.phone.replace(/\D/g, "").length !== 10) {
			toast.error(ERRORS.PHONE_NUM_LENGTH_ERROR);
		} else {
			try {
				setLoading(true);
				setDisabledButton(true);
				const imgsUrl = await uploadFiles(images, profile.clientName);
				await addBookingSubmission({ ...form }, imgsUrl, profile);
				toast.success(MESSAGE.SUCCESS_FULL);
				resetForm();
			} catch (err) {
				catchCallback(err);
			} finally {
				setLoading(false);
				setDisabledButton(false);
			}
		}
	};

	const catchCallback = (err) => {
		toast.error(ERRORS.FORM_FAIL);
	};

	const resetForm = () => {
		setImages([]);
		setForm({ ...initialState, service: { ...initialState.service } });
		navigate(location.pathname, { replace: true });
	};

	const checkboxHandler = (e) => {
		const { name, checked } = e.target;
		setForm({ ...form, service: { ...form.service, [name]: checked } });
	};

	const toggleDropDown = () => {
		setOpenDropDown(!openDropDown);
	};

	const fileHandler = (e) => {
		setImages([...images, ...e.target.files]);
	};

	const removeImage = (imgName) => {
		const image = images.filter((img) => img.name !== imgName);
		setImages(image);
	};

	return (
		<div className="pt-20 min-h-screen">
			<main className="w-full md:w-[500px] px-[5%] md:px-0 lg:[w-800px] mx-auto">
				<Logo logoUrl={logoUrl} />
				<form className="mt-10" onSubmit={formSubmitHandler}>
					<Input
						type="text"
						name="firstName"
						value={form.firstName}
						label="First Name"
						onChange={inputHandler}
					/>

					<Input
						type="text"
						name="lastName"
						value={form.lastName}
						label="Last Name"
						onChange={inputHandler}
					/>

					<Input
						type="email"
						name="email"
						value={form.email}
						label="Email"
						onChange={inputHandler}
					/>

					<Input
						type="tel"
						name="phone"
						value={form.phone}
						label="Phone Number"
						onChange={phoneNumberHandler}
					/>

					<Input
						type="text"
						name="address"
						value={form.address}
						label="Address"
						onChange={inputHandler}
					/>

					<Input type="text" name="city" value={form.city} label="City" onChange={inputHandler} />

					<Input
						type="text"
						name="state"
						value={form.state}
						label="State"
						onChange={inputHandler}
					/>

					<Input
						label="Zip Code"
						type="tel"
						name="zip"
						value={form.zip}
						onChange={inputHandler}
						maxLength={"5"}
					/>

					<TextArea
						label="Tell us about your project"
						name="description"
						value={form.description}
						onChange={inputHandler}
					/>

					<DropDown
						value={form.aboutUs}
						onClick={(val) => setForm({ ...form, aboutUs: val })}
						data={ZARAZAU_ABDOUT_US}
						label="How did you hear about us"
						isOpen={openDropDown}
						toggleDropDown={toggleDropDown}
					/>

					<div className="mb-4">
						<Label text="Please select the service/s you required" required={true} />
						{Object.entries(form.service).map(([key, value]) => {
							return (
								<div key={key} className="mb-2">
									<CheckBox
										label={ZARAZAU_SERVICES[key]}
										checked={value}
										name={key}
										onChange={checkboxHandler}
									/>
								</div>
							);
						})}
					</div>

					<ImageUploader images={images} onChange={fileHandler} removeImage={removeImage} />

					<SubmitButton loading={loading} disabled={disabledButton} />
				</form>
			</main>
		</div>
	);
};

export default Zarazau;
