import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { db } from '../firebase.js'
import { getDoc, doc } from 'firebase/firestore'
import { addBookingSubmission } from '../utils/bookingService.js'
import { uploadFiles } from '../utils/FirebaseStorage.js'
import Input from '../components/Input.js'
import file_upload from '../assets/images/camera.png'
import { initWidget } from '../utils/chatWidget.js'
import GoogleChatWigdet from '../components/GoogleChatWigdet.js'
import { toast } from 'react-toastify'
import { MESSAGE } from '../constants/Message'
import { ERRORS } from '../constants/Errors'
import TextArea from '../components/TextArea'
import { validateForm } from '../utils/Validations.js'
import { formatMobileNumber } from '../utils/formatters.js'
import SubmitButton from '../components/Button/SubmitButton.js'
import Logo from '../components/Logo/Logo.js'
import DropDown from '../components/DropDown/DropDown.js'
import { HOME_OWNER, TYPE_OF_CUSTOMER, WELL_TYPE } from '../utils/consts.js'
import { useLocation, useNavigate } from 'react-router-dom'


const formInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  description: '',
  customerType: '',
  homeOwner: '',
  numOfPeople: '',
  numOfBathroom: '',
  wellType: '',
}

const PureWaterNorthWestForm = ({ profile }) => {
  const searchParams = new URLSearchParams(document.location.search)

  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [logoUrl, setLogoUrl] = useState()
  const [dropDown, setDropDown] = useState(null)
  const [form, setForm] = useState(formInitialState)
  const [disabledButton, setDisabledButton] = useState(false)

  const widget = initWidget()
  const location = useLocation()
  const navigate = useNavigate()

  const getClientLogo = useCallback(async () => {
    const docRef = doc(db, 'logos', profile.clientName)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      setForm({ ...form,
        firstName: !searchParams.get('fn') ? '' : searchParams.get('fn'),
        lastName: !searchParams.get('ln') ? '' : searchParams.get('ln'),
        email: !searchParams.get('email') ? '' : searchParams.get('email'),
        phone: !searchParams.get('phone') ? '' : searchParams.get('phone'),
        address: !searchParams.get('address') ? '' : searchParams.get('address'),
        city:  !searchParams.get('city') ? '' : searchParams.get('city'),
        state: !searchParams.get('state') ? '' : searchParams.get('state'),
        zip:  !searchParams.get('zip') ? '' : searchParams.get('zip'),
        description: !searchParams.get('desc') ? '' : searchParams.get('desc')
      })
      setLogoUrl(docSnap.data().logoUrl)
    }
  },[profile.clientName])

  useEffect(() => {
    getClientLogo()

  }, [getClientLogo])

  const inputHandler = (e) => {
    const { value, type, name } = e.target
    const val = type === 'tel' ? value.replace(/\D/g, '') : value
    setForm({ ...form, [name]: val })
  }

  const phoneNumberHandler = (e) => {
		const number = formatMobileNumber(e.target.value);
		setForm({ ...form, phone: number });
	}

  const fileHandler = (e) => {
    setImages([...images, ...e.target.files])
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()

    if (validateForm(form, profile.clientName)) {
      toast.error(ERRORS.ALL_FIELDS)
    } else if (images && images.length > 5) {
      toast.error(ERRORS.IMAGES_LENGTH)
    } else if (form.phone.length < 10) {
      toast.error(ERRORS.PHONE_NUM_LENGTH_ERROR)
    } else {
      
      try {
        setLoading(true)
        setDisabledButton(true)
        const imgsUrl = await uploadFiles(images, profile.clientName)
        await addBookingSubmission(form, imgsUrl, profile)
        toast.success(MESSAGE.SUCCESS_FULL)
        resetForm()
      } catch (err) {
        catchCallback(err)
      } finally {
        setLoading(false)
        setDisabledButton(false)
      }
    }
  }

  const catchCallback = (err) => {
    toast.error(ERRORS.FORM_FAIL)
    console.error(err)
  }

  const resetForm = () => {
    setImages([])
    setForm(formInitialState)
    navigate(location.pathname, { replace: true })
  }

  const removeImage = (imgName) => {
    const image = images.filter((img) => img.name !== imgName)
    setImages(image)
  }

  const toggleDropDown = (val) => {
    if (dropDown === val) {
      setDropDown(null)
    } else {
      setDropDown(val)
    }
  }

  return (
    <div className='pt-20 min-h-screen'>
      <main className='w-full md:w-[500px] px-[5%] md:px-0 lg:[w-800px] mx-auto'>
        <Logo logoUrl={logoUrl} />
        <form className='mt-10' onSubmit={formSubmitHandler}>
          <div>
            <Input
              type='text'
              name='firstName'
              value={form.firstName}
              label='First Name'
              onChange={inputHandler}
            />

            <Input
              type='text'
              name='lastName'
              value={form.lastName}
              label='Last Name'
              onChange={inputHandler}
            />

            <Input
              type='email'
              name='email'
              value={form.email}
              label='Email'
              onChange={inputHandler}
            />

            <Input
              type='tel'
              name='phone'
              value={form.phone}
              label='Phone Number'
              onChange={phoneNumberHandler}
            />

            <Input
              type='text'
              name='address'
              value={form.address}
              label='Address'
              onChange={inputHandler}
            />

            <Input
              type='text'
              name='city'
              value={form.city}
              label='City'
              onChange={inputHandler}
            />
            
            <Input
              type='text'
              name='state'
              value={form.state}
              label='State'
              onChange={inputHandler}
            />
             
            <Input
              label='Zip'
              type='tel'
              name='zip'
              value={form.zip}
              onChange={inputHandler}
              maxLength="5"
            />

            <DropDown
              label={'Type of customer'}
              value={form.customerType}
              data={TYPE_OF_CUSTOMER}
              onClick={(val) => setForm((prevStat) => ({ ...prevStat, customerType: val }))}
              key={'customerType'}
              isOpen={'customerType' === dropDown}
              toggleDropDown={() => toggleDropDown('customerType')}
            />

            <DropDown
              label={'Home Owner'}
              value={form.homeOwner}
              data={HOME_OWNER}
              onClick={(val) => setForm((prevStat) => ({ ...prevStat, homeOwner: val }))}
              key={'homeOwner'}
              isOpen={'homeOwner' === dropDown}
              toggleDropDown={() => toggleDropDown('homeOwner')}
            />

            <Input
              label={'Number of people in the home?'}
              type='tel'
              name='numOfPeople'
              value={form.numOfPeople}
              onChange={inputHandler}
            />

            <Input
              label={'Number of bathrooms'}
              type='tel'
              name='numOfBathroom'
              value={form.numOfBathroom}
              onChange={inputHandler}
            />

            <DropDown
              label={'Private well or community well/municipality'}
              value={form.wellType}
              data={WELL_TYPE}
              onClick={(val) => setForm((prevStat) => ({ ...prevStat, wellType: val }))}
              isOpen={'wellType' === dropDown}
              toggleDropDown={() => toggleDropDown('wellType')}
            />

            <TextArea
              label={'Tell us about your project'}
              name='description'
              value={form.description}
              onChange={inputHandler}
            />
          </div>

          <div className='mt-4'>
            <div className='py-3'>
              <p className='text-center font-semibold'>
                Please provide a photo of your project.
              </p>
              <p className='text-center font-semibold'>
                These will give us a better understanding of your project. We can then make recommendations to you and provide a quote.
              </p>
            </div>

            <div className='bg-gray-100 w-full rounded-md py-6'>
              <div className='flex justify-center flex-col items-center'>
                <p className='mb-2 font-semibold'>Click on icon to select images</p>
                <label htmlFor='filesUploader' className='cursor-pointer'>
                  <img src={file_upload} alt='file icon' width='100' height='100' />
                </label>
                <input
                  type='file'
                  id='filesUploader'
                  onChange={fileHandler}
                  multiple
                  hidden={true}
                  accept="image/*, video/*"
                />
                { (images &&
                  <ul className='text-black'>
                  {
                    images.length > 0 && images.map((image, i) => {
                      return (
                        <li key={i}>{image.name} <span className='cursor-pointer' onClick={() => removeImage(image.name)}>&#10006;</span></li>
                      )
                    })
                  }
                  </ul>) ?? null
                }
              </div>
            </div>
          </div>

          <SubmitButton loading={loading} disabled={disabledButton} />
        </form>
      </main>

      {widget && <GoogleChatWigdet client_id={profile.clientName} />}
    </div>
  )
}

export default PureWaterNorthWestForm
