import React, { useState, useEffect, useCallback } from 'react'
import { db } from '../firebase.js'
import { getDoc, doc } from 'firebase/firestore'
import { addBookingSubmission } from '../utils/bookingService.js'
import { uploadFiles } from '../utils/FirebaseStorage.js'
import Input, { CheckBox } from '../components/Input.js'
import file_upload_img from '../assets/images/camera.png'
import { initWidget } from '../utils/chatWidget.js'
import GoogleChatWigdet from '../components/GoogleChatWigdet.js'
import { toast } from 'react-toastify'
import { MESSAGE } from '../constants/Message'
import { ERRORS } from '../constants/Errors'
import TextArea from '../components/TextArea'
import { validateForm } from '../utils/Validations.js'
import { DESCRIPTION_TEXT, ESTIMATED_FIELDS, HIDE_IMAGE_WIDGET, ZIP_CODE_LENGTH, ZIP_CODE_TYPE } from '../utils/consts.js'
import SubmitButton from '../components/Button/SubmitButton.js'
import Logo from '../components/Logo/Logo.js'
import { redirectUser } from '../utils/helpers.js'
import { useLocation, useNavigate } from 'react-router-dom'
import { isValid } from "postcode";

const formInitialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  description: '',
  sq_feet: '',
  special_attention: '',
  firstWorkDay: '',
  secondWorkDay: '',
  arrivalTime: {
    anyTime: false,
    morning: false,
    afternoon: false,
    evening: false,
  },
  aboutUs: '',
  employer: ''

}

const BookingService = ({ profile }) => {

  const searchParams = new URLSearchParams(document.location.search)

  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [logoUrl, setLogoUrl] = useState()
  const [optSms, setOptSms] = useState(false)
  const [form, setForm] = useState(formInitialState)
  const [disabledButton, setDisabledButton] = useState(false)

  const widget = initWidget()
  const isBlackManPi = profile.clientName === 'blackmanpi'
  const isRpiGoogle = profile.clientName === 'rpi_google'
  const isWestOverPlumbing = profile.clientName === 'westover'

  const location = useLocation()
  const navigate = useNavigate()

  const getClientLogo = useCallback(async () => {
    const docRef = doc(db, 'logos', profile.clientName)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      setForm({ ...form,
        firstName: !searchParams.get('fn') ? '' : searchParams.get('fn'),
        lastName: !searchParams.get('ln') ? '' : searchParams.get('ln'),
        email: !searchParams.get('email') ? '' : searchParams.get('email'),
        phone: !searchParams.get('phone') ? '' : searchParams.get('phone'),
        address: !searchParams.get('address') ? '' : searchParams.get('address'),
        city:  !searchParams.get('city') ? '' : searchParams.get('city'),
        state: !searchParams.get('state') ? '' : searchParams.get('state'),
        zip:  !searchParams.get('zip') ? '' : searchParams.get('zip'),
        description: !searchParams.get('desc') ? '' : searchParams.get('desc')
      })
      setLogoUrl(docSnap.data().logoUrl)
    }
  },[profile.clientName])

  useEffect(() => {
    getClientLogo()

  }, [getClientLogo])

  const inputHandler = (e) => {
    const { value, type, name } = e.target
    const val = type === 'tel' ? value.replace(/\D/g, '') : value
    setForm({ ...form, [name]: val })
  }

  const phoneNumberHandler = (e) => {
		const number = e.target.value;
		setForm({ ...form, phone: number });
	};

  const fileHandler = (e) => {
    setImages([...images, ...e.target.files])
  }

  const formSubmitHandler = async (e) => {
    e.preventDefault()

    if (validateForm(form, profile.clientName)) {
      toast.error(ERRORS.ALL_FIELDS)
    } else if (images && images.length > 5) {
      toast.error(ERRORS.IMAGES_LENGTH)
    } else {

      if (profile.clientName === 'guttervacni' && !isValid(form.zip)) {
        toast.error(ERRORS.POST_CODE_WRONG)
        return
      }

      try {
        setLoading(true)
        setDisabledButton(true)
        const imgsUrl = await uploadFiles(images, profile.clientName)
        await addBookingSubmission({ ...form, optSms }, imgsUrl, profile)
        toast.success(MESSAGE.SUCCESS_FULL)
        redirectUser(profile.clientName)
        resetForm()
      } catch (err) {
        catchCallback(err)
      } finally {
        setLoading(false)
        setDisabledButton(false)
      }
    }
  }

  const catchCallback = (error) => {
    toast.error(error.response.data.message ?? ERRORS.FORM_FAIL)
  }

  const resetForm = () => {
    setImages([])
    setForm(formInitialState)
    navigate(location.pathname, { replace: true })
  }

  const removeImage = (imgName) => {
    const image = images.filter((img) => img.name !== imgName)
    setImages(image)
    setOptSms(false)
  }

  const checkboxHandler = (e) => {
    const { name, checked } = e.target
    setForm({ ...form, arrivalTime: { ...form.arrivalTime, [name]: checked }})
  }

  return (
    <div className='pt-20 min-h-screen'>
      <main className='w-full md:w-[500px] px-[5%] md:px-0 lg:[w-800px] mx-auto'>
        <Logo logoUrl={logoUrl} />
        <form className='mt-10' onSubmit={formSubmitHandler}>
          <div>
            <Input
              type='text'
              name='firstName'
              value={form.firstName}
              label='First Name'
              onChange={inputHandler}
            />

            <Input
              type='text'
              name='lastName'
              value={form.lastName}
              label='Last Name'
              onChange={inputHandler}
            />

            <Input
              type='email'
              name='email'
              value={form.email}
              label='Email'
              onChange={inputHandler}
            />

            <Input
              type={profile.clientName === 'guttervacni' ? 'text' : 'tel'}
              name='phone'
              value={form.phone}
              label='Mobile Number'
              onChange={phoneNumberHandler}
            />

            {
              !(isBlackManPi || isRpiGoogle ) &&
              <Input
                type='text'
                name='address'
                value={form.address}
                label='Address'
                onChange={inputHandler}
              />
            }

            {
              !(isBlackManPi || isRpiGoogle ) &&
              <Input
                type='text'
                name='city'
                value={form.city}
                label='City'
                onChange={inputHandler}
              />
            }

            {
              !(isBlackManPi || isRpiGoogle ) &&
              <Input
                type='text'
                name='state'
                value={form.state}
                label={profile.clientName === 'guttervacni' ? 'County' : 'State'}
                onChange={inputHandler}
              />
            }

            {
              !isBlackManPi &&
              <Input
                label={profile.clientName === 'guttervacni' ? 'PostCode' : 'Zip Code'}
                type={ZIP_CODE_TYPE[profile.clientName] ?? 'tel'}
                name='zip'
                value={form.zip}
                onChange={inputHandler}
                maxLength={ZIP_CODE_LENGTH[profile.clientName] ?? '5'}
              />
            }

            { ESTIMATED_FIELDS.includes(profile.clientName) &&
              <>
                <Input
                  label='Estimated Square Footage of Home'
                  type='number'
                  name='sq_feet'
                  value={form.sq_feet}
                  onChange={inputHandler}
                />

                <TextArea
                  label='Are there areas of your home that you will need special attention OR Are there items and/or areas you are not interested in having serviced?'
                  name='special_attention'
                  value={form.special_attention}
                  onChange={inputHandler}
                />
              </>
            }

            {
              profile.clientName === 'aflac' && 
              <Input
                label='Employeer / Business Name'
                type='text'
                name='employer'
                value={form.employer}
                onChange={inputHandler}
              />
            }

            {
              !isRpiGoogle &&
              <TextArea
                label={DESCRIPTION_TEXT[profile.clientName] ?? 'Tell us about your project'}
                name='description'
                value={form.description}
                onChange={inputHandler}
              />
            }
          </div>

          {isBlackManPi &&
          <div className='mb-2'>
            <CheckBox
              label={'Opt me in for text messages!'}
              checked={optSms}
              name={'optSms'}
              onChange={(e) => { setOptSms(e.target.checked) }}
            />
          </div>
          }

          {isBlackManPi &&
          <>
            <p className='text-sm'>
              By submitting this form, you agree to receive telephone calls and text messages at anytime, which include hours outside of business hours (8:00 a.m. PST - 9:00 p.m. PST). This is so that we may reach you as soon as possible in order to consult on your potential case.
            </p>
            <div className='flex justify-center w-full mt-5'>
              <a href='https://www.blackmanpi.com/privacy-policy.html' className='text-sm text-blue-600 font-medium'>Privacy Policy</a>
            </div>
          </>  
          }
          
          {HIDE_IMAGE_WIDGET[profile.clientName] ? null : <div className='mt-4'>
            <div className='py-3'>
              {
                ESTIMATED_FIELDS.includes(profile.clientName)  ?
                <p className='text-center font-semibold'>
                  To help us provide a speedy estimate, please include photos of rooms that need to be cleaned
                </p>
                :
                <>
                  <p className='text-center font-semibold'>Please provide a photo of your project.</p>
                  <p className='text-center font-semibold'>
                    These will give us a better understanding of your project. We can then make recommendations to you and provide a quote.
                  </p>
                </>
              }
            </div>

            <div className='bg-gray-100 w-full rounded-md pb-6 pt-3'>
              { !isBlackManPi && <div className='flex justify-center flex-col items-center'>
                <p className='mb-2 font-semibold'>Click on icon to select images</p>
                <label htmlFor='filesUploader' className='cursor-pointer'>
                  <img
                    src={file_upload_img}
                    alt='file upload icon'
                    width='100' height='100'
                  />
                </label>
                <input
                  type='file'
                  id='filesUploader'
                  onChange={fileHandler}
                  multiple
                  hidden={true}
                  accept="image/*, video/*"
                />
                { (images &&
                  <ul className='text-black'>
                  {
                    images.length > 0 && images.map((image, i) => {
                      return (
                        <li key={i}>{image.name} <span className='cursor-pointer' onClick={() => removeImage(image.name)}>&#10006;</span></li>
                      )
                    })
                  }
                  </ul>) ?? ''
                }
              </div>}
            </div>
          </div>}

          {isWestOverPlumbing && 
            <div className='mt-4'>
              <p className="font-semibold mb-2">Your Availability</p>

              <Input
                label='Which day would be best for an assessment of the work?'
                type='date'
                name='firstWorkDay'
                value={form.firstWorkDay}
                onChange={inputHandler}
              />

              <Input
                label='What is another day that works for you?'
                type='date'
                name='secondWorkDay'
                value={form.secondWorkDay}
                onChange={inputHandler}
                required={false}
              />

              <div className='mb-4'>
                <p className='font-semibold mb-2'>What are your preferred arrival times? <span className='text-gray-400 font-normal'>(optional)</span></p>

                <div className='mb-2'>
                  <CheckBox
                    label={'Any Time'}
                    checked={form.arrivalTime.anyTime}
                    name={'anyTime'}
                    onChange={checkboxHandler}
                  />
                </div>

                <div className='mb-2'>
                  <CheckBox
                    label={'Morning'}
                    checked={form.arrivalTime.morning}
                    name={'morning'}
                    onChange={checkboxHandler}
                  />
                </div>

                <div className='mb-2'>
                  <CheckBox
                    label={'Afternoon'}
                    checked={form.arrivalTime.afternoon}
                    name={'afternoon'}
                    onChange={checkboxHandler}
                  />
                </div>

                <div className='mb-2'>
                  <CheckBox
                    label={'Evening'}
                    checked={form.arrivalTime.evening}
                    name={'evening'}
                    onChange={checkboxHandler}
                  />
                </div>
              </div>

              <div>
                <p className='font-bold text-lg mb-2'>How did you hear about us?</p>
                <p className='font-semibold mb-2'>How did you hear about Westover Plumbing? <span className='text-gray-400 font-normal'>(optional)</span></p>
                <TextArea
                  required={false}
                  name='aboutUs'
                  value={form.aboutUs}
                  onChange={inputHandler}
                />
              </div>
            </div>
          }

          <SubmitButton loading={loading} disabled={disabledButton} />
        </form>
      </main>

      {widget && <GoogleChatWigdet client_id={profile.clientName} />}
    </div>
  )
}

export default BookingService
