import React from 'react'

const Footer = ({ onClick }) => {
  return (
    <div className='bg-[#343a40] text-center py-2 italic text-lg'> 
        <p className='mb-2 text-white'>Powered by</p>
        <p onClick={onClick} className='text-[#007bff] cursor-pointer underline'>
          SnapPicFix
        </p>
      </div>
  )
}

export default Footer